<template>
  <div class="inner-layout">

    <div class="page-title">고객센터</div>

    <cs-center-tab />

    <div class="filter-btn-section btn-set">
      <div>
        <router-link to="/inquiry/new" class="btn w-100 btn-outline color-6">1:1문의</router-link>
      </div>
    </div>

    <div class="board-container board-inquiry-list">
      <div v-for="item in list" :key="`inquiry-${item.idx}`" class="board-item" @click="goDetail(item.idx)">
        <div class="board-title-box">
          <span v-if="item.answer == 0" class="chip chip-outline color-6">답변대기</span>
          <span v-if="item.answer == 1" class="chip chip-outline color-1">답변완료</span>
          <span class="title"><span class="color-1">Q. </span>{{ item.title }}</span>
          <span class="date">{{ $getDateFormat(item.createDate, 'Y.m.d') }}</span>
        </div>
      </div>
      <div v-if="!list || list.length === 0" class="noDataMessage2">{{ $msg('nodata2') }}</div>
    </div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import listMixin from "@/mixins/listMixin"
export default {
  name: "inquiryList",
  mixins: [listMixin],
  data() {
    return {
      page: 1,
      pageSize: 20,
    }
  },
  methods: {
    async infiniteHandler($state) {
      try {
        const { result, list } = await this.$api.getInquiryList({
          page: this.page,
          pageSize: this.pageSize,
        })
        if (result === 'success' && list && list.length > 0) {
          this.list = this.page == 1 ? list : this.list.concat(list)
          setTimeout(() => {
            this.page += 1;
            $state.loaded();
          }, 1000);
        }
        else {
          $state.complete();
        }
      } catch (error) {
        console.log(error);
      }
    },
    goDetail(idx) {
      this.setBeforeContent()
      this.$router.push(`/inquiry/${idx}`)
    },
  }
}
</script>
